<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="allCardList">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title py-3">
              <i class="fa ri-question-answer-fill" aria-hidden="true" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
              Quizzes & Competitions
              <span class="btn_in_header">
                <span class="pull-right" v-if='userPermission("app_add")'>
                    <b-button variant="primary mb-2" @click="appAdd()">Add (+)</b-button>
                  </span>
                  <span class="pull-right mr-2" v-if="userPermission('event_list_dashboard')">
                    <b-button variant="primary mb-2" @click="goToEventDashboard">Dashboard</b-button>
                  </span>
              </span>
            </h4>
          </template>
        </iq-card>
        <iq-card>
          <b-col md="12" class="p-0">
            <iq-card v-if="!isHidden" class="iq-card" style="width:100%;" >
              <div class="iq-card-body p-0 showFlexListDiv">
                <div class="user-tabing showFlexList">
                  <tab-nav :pills="true" id="pills-tab" class="nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0 w-100">
                    <div class="flex-fill p-0" @click="activeTab = 'upcomingQuiz'">
                      <tab-nav-items class="flex-fill p-0" :active="activeTab == 'upcomingQuiz'" :ariaSelected="activeTab == 'upcomingQuiz'" id="pills-all-quiz" href="#profile-all-quiz" ariaControls="pills-all-quiz" role="tab"  title="Quiz" />
                    </div>
                    <!--
                    <div class="flex-fill p-0" @click="activeTab = 'game';getExamList(0)">
                      <tab-nav-items class="flex-fill p-0" :active="activeTab == 'game'" :ariaSelected="activeTab == 'game'" id="pills-all-quiz" href="#profile-all-quiz" ariaControls="pills-all-quiz" role="tab"  title="Games" />
                    </div>
                    -->
                    <div class="flex-fill p-0" @click="activeTab = 'quizResults'">
                      <tab-nav-items  class="flex-fill p-0" :active="activeTab == 'quizResults'" :ariaSelected="activeTab == 'quizResults'"  id="pills-my-quiz" href="#profile-my-quiz"  ariaControls="pills-my-quiz" role="tab" :title="`Results`" />
                    </div>
                  </tab-nav>
                </div>
              </div>
            </iq-card>
          </b-col>
        </iq-card>
        <div v-if="!isHidden" class="row gide_mobile_view pl-3" :key="activeTab+'tab'">
          <tab-content-item v-if="activeTab === 'upcomingQuiz'" :active="activeTab === 'upcomingQuiz'" id="pills-my-quiz" aria-labelled-by="pills-my-quiz" class="row w-100">
            <template v-for="(quiz, index) of upcomingExamList">
              <b-col class="mobileNoPadding col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-3" :key="quiz.app_id + index + 'quizU'">
                <!-- @todo The Below if has to be removed after changes to backend API. Get Role Specific Event -->
              <iq-card class="rounded iq-card-block iq-card-stretch iq-card-height">
                <div class="event-images" @click="quizDetail(quiz.app_id)">
                  <a style="cursor: pointer;">
                    <img class="img-fluid image image_eventpost" alt="" :src="quiz.app_image"/>
                  </a>
                </div>
                <div class="row pl-3 pr-3">
                  <div class="col-12" >
                    <b-badge
                    class="border mr-2 mb-2 font-weight-normal"
                    variant="primary"
                    style="font-size: 11px; font-weight: bold !important;"
                  >
                  {{quiz.app_is_gideplus == 0 ? 'Gide' : 'Gide Plus'}}
                  </b-badge>
                  </div>
                </div>
                <b-card-body class="iq-card-body rsvpCardBody">
                  <div class="webKitBox" @click="quizDetail(quiz.app_id)">
                    <div class="date-of-event">
                      <span>{{quiz.app_date_ts | monthOnly}}</span>
                      <h5>
                        {{quiz.app_date_ts | dateOnly}}
                      </h5>
                    </div>
                    <div class="events-detail ml-3" style="cursor: pointer;">
                      <h5 class="twoLineOnly pr-3">
                        {{ quiz.app_name }}
                      </h5>
                      <p class="threeLineOnly pr-3 vhtmlTag vueHtmlParent mt-3 mb-0 vhtmlUlLi" v-html="quiz.app_description" style="height: 55px; overflow: hidden;">
                      </p>
                      <span v-if="quiz.has_timer">
                        <b-badge class="mr-2" style="background: rgb(51, 102, 204) !important;">
                          Duration : {{quiz.app_duration | durationToDHM}}
                        </b-badge>
                      </span>
                    </div>
                  </div>
                  <b-btn class="w-100 mr-2 mt-3 iq-bg-dark rsvpBtn" size="md" style="border: none;" @click="quizDetail(quiz.app_id)">
                    View {{quiz.app_type == QuizType.COMPETITION ? 'Challenge' : 'Quiz'}}
                  </b-btn>
                </b-card-body>
              </iq-card>
              </b-col>
            </template>
          </tab-content-item>
          <tab-content-item v-if="activeTab === 'game'" :active="activeTab === 'game'" id="pills-my-quiz" aria-labelled-by="pills-my-quiz" class="row w-100">
            <template v-for="(game, index) of appGamesList">
              <b-col class="mobileNoPadding col-12 col-sm-6 col-md-4 col-lg-4" :key="game.app_id + index + 'game'">
                <iq-card class="rounded iq-card-block iq-card-stretch iq-card-height" >
                  <div class="event-images" @click="goToGamePage(game)">
                    <a style="cursor: pointer;">
                      <img class="img-fluid image image_eventpost" alt="" :src="game.app_image"/>
                    </a>
                  </div>
                  <div class="row pl-3 pr-3">
                    <div class="col-12" >
                      <b-badge
                      class="border mr-2 mb-2 font-weight-normal"
                      variant="primary"
                      style="font-size: 11px; font-weight: bold !important;"
                    >
                      {{game.app_is_gideplus == 0 ? 'Gide' : 'Gide Plus'}}
                    </b-badge>
                    </div>
                  </div>
                  <b-card-body class="iq-card-body rsvpCardBody" @click="goToGamePage(game)">
                    <div class="webKitBox">
                      <div class="date-of-event">
                        <span>{{game.app_date_ts | monthOnly}}</span>
                        <h5>
                          {{game.app_date_ts | dateOnly}}
                        </h5>
                      </div>
                      <div class="events-detail ml-3" style="cursor: pointer;">
                        <h5 class="twoLineOnly pr-3">
                          {{ game.app_name }}
                        </h5>
                        <p class="threeLineOnly pr-3 vhtmlTag vueHtmlParent mt-3 mb-0 vhtmlUlLi" v-html="game.app_description" style="height: 55px; overflow: hidden;">
                        </p>
                      </div>
                    </div>
                    <b-btn class="w-100 mr-2 mt-3 iq-bg-dark rsvpBtn" size="md" style="border: none;" @click="goToGamePage(game)">
                      Play Game
                    </b-btn>
                  </b-card-body>
                </iq-card>
              </b-col>
            </template>
          </tab-content-item>
          <tab-content-item v-if="activeTab === 'quizResults'" :active="activeTab === 'quizResults'" id="pills-my-quiz" aria-labelled-by="pills-my-quiz" class="row w-100">
            <b-col class="col-12 col-sm-6 col-md-4 col-lg-4" v-for="(item, index) of quizResultList" :key="item.app_id + index + 'quizmyQuiz'">
              <!-- @todo The Below if has to be removed after changes to backend API. Get Role Specific Event -->
              <iq-card class="rounded iq-card-block iq-card-stretch iq-card-height" >
                <div class="event-images" @click="goToQuizResultView(item)">
                  <a style="cursor: pointer;">
                    <img class="img-fluid image image_eventpost" alt="" :src="item.app_image"/>
                  </a>
                </div>
                <div class="row pl-3 pr-3">
                  <div class="col-12" >
                    <b-badge
                    class="border mr-2 mb-2 font-weight-normal"
                    variant="primary"
                    style="font-size: 11px; font-weight: bold !important;"
                  >
                    {{item.app_is_gideplus == 0 ? 'Gide' : 'Gide Plus'}}
                  </b-badge>
                  </div>
                </div>
                <b-card-body class="iq-card-body rsvpCardBody">
                  <div class="webKitBox">
                    <div class="date-of-event">
                      <span>{{item.app_date_ts | monthOnly}}</span>
                      <h5>
                        {{item.app_date_ts | dateOnly}}
                      </h5>
                    </div>
                    <div class="events-detail ml-3" style="cursor: pointer;">
                      <h5 class="twoLineOnly pr-3">
                        {{ item.app_name }}
                      </h5>
                      <p class="threeLineOnly pr-3 vhtmlTag vueHtmlParent mt-3 mb-0 vhtmlUlLi" style="height: 55px; overflow: hidden;" v-html="item.app_description">
                      </p>
                      <span >
                        <b-badge class="mr-2" style="background: rgb(51, 102, 204) !important;">
                          Percentage : {{item.aprs_percentage}}%
                        </b-badge>
                        <b-badge class="mr-2" style="background: rgb(51, 102, 204) !important;">
                          percentile {{item.aprs_percentile}}
                        </b-badge>
                      </span>
                    </div>
                  </div>
                  <b-btn class="w-100 mr-2 mt-3 rsvpBtn" @click="goToQuizResultView(item)" size="md" style="border: none;">
                    Result
                  </b-btn>
                </b-card-body>
              </iq-card>
          </b-col>
          </tab-content-item>
          </div>
      </b-col>
    </b-row>
    <div class="mb-4" style="display: block;!important">
      <button class="btn btn-primary load-more-btn" @click="loadMore()">
        Load More
      </button>
    </div>
  </b-container>
</template>
<style lang="scss" scoped>
@media (max-width: 485px) {
    .eventBannerContainer .mobileViewEvent {
      padding: 0px !important;
      text-align: end;
      .DontShow {
        display: none;
      }
      .social-info {
        margin-top: -28px !important;
        padding: 13px;
      }
    }
  }
  .rsvpCardBody .rsvpBtn {
    background: green !important;
    color: #fff !important;
  }
  .image {
    width: 100%;
    max-width: 100%;
    min-height: 200px;
  }
  .weblinkPara {
    height: 30px;
    overflow: hidden;
    padding-right: 26px;
  }
  .load-more-btn {
    margin: auto;
    display: block;
  }
  .event-images .image.image_eventpost {
    width: 100%;
    height: auto !important;
  }
@media screen and (max-width: 600px) {
  .gide_mobile_view{
    justify-content: center;
  }
}
@media (max-width: 575px){
  .mobileNoPadding {
      padding: unset !important;
      padding-left: 0px !important;
      padding-right: 10px !important;
  }
}
.Result{
  display: block;
  margin: auto;
  background: transparent;
  border: 0px;
  color: #e5252c;
}
.showFlexListDiv .showFlexList .profile-feed-items{
  min-width: 100%;
}
</style>
<script>
import moment from "moment"
import { socialvue } from "../../../config/pluginInit"
import { AppResults } from "../../../FackApi/api/appResults.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import { Apps } from "../../../FackApi/api/apps.js"
import QuizType from "../../../FackApi/json/QuizType.js"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "QuizResultDashboard",
  data () {
    return {
      quizResultList: [],
      appGamesList: [],
      activeTab: "upcomingQuiz",
      upcomingExamList: [],
      pastExamList: [],
      upcomingExamLoaded: false,
      pastExamLoaded: false,
      limit: 10,
      isHidden: false,
      QuizType: QuizType
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    socialvue.index()
    this.QuizType = QuizType(this.userData.user_role)
    this.getExamList()
    this.getQuizResultList()
  },
  methods: {
    /**
     * userPermission
     */
    userPermission (actionName) {
      return userPermission(this, this.userData, actionName)
    },
    /**
     * getQuizResultList
     */
    async getQuizResultList () {
      const resultListResp = await AppResults.appResultList(this)
      if (!resultListResp.resp_status) {
        ApiResponse.responseMessageDisplay(this, resultListResp)
      }

      this.quizResultList = resultListResp.resp_data ? resultListResp.resp_data.data : null
    },
    /**
     * goToEventDashboard
     */
    goToEventDashboard () {
      this.$router.push("/quiz_list")
    },
    /**
     * loadMore
     */
    loadMore () {
      if (this.activeTab === "upcomingQuiz") { // quiz
        this.getExamList()
      }
      else if (this.activeTab === "game") { // game
        this.getExamList(0)
      }
    },
    /**
     * getExamList
    */
    async getExamList (upcoming = 1) {
      let filter = { upcoming: upcoming, limit: this.limit, offset: 0, user_role: this.userData.user_role }

      if (this.activeTab === "upcomingQuiz") {
        filter.offset = this.upcomingExamList.length
        filter.type = QuizType.QUIZ
      }
      else if (this.activeTab === "game") {
        filter.offset = this.appGamesList.length
        filter.type = QuizType.GAME_HANGMAN
      }

      const quizListResp = await Apps.appList(this, { filter: JSON.stringify(filter) })
      if (!quizListResp.resp_status) {
        if (this.activeTab === "upcomingQuiz") {
          this.upcomingExamLoaded = true
        }
        else if (this.activeTab === "game") {
          this.pastExamLoaded = true
        }
        return ApiResponse.responseMessageDisplay(this, quizListResp)
      }
      if (this.activeTab === "upcomingQuiz") {
        this.upcomingExamList = [ ...this.upcomingExamList, ...quizListResp.resp_data.data ]
      }
      else if (this.activeTab === "game") {
        this.appGamesList = [ ...this.appGamesList, ...quizListResp.resp_data.data ]
      }
    },
    /**
     * goToQuizResultView
     */
    goToQuizResultView (item) {
      this.$router.push(`/app_result_view/${item.app_id}/${this.userData.user_id}/0`) // 0: dont Show, 1: Show:: info message on top of the result page
    },
    /**
     * appAdd
     */
    appAdd () {
      this.$router.push("/app_add")
    },
    /**
    * goToGamePage
    */
    goToGamePage (item) {
      this.$router.push(`/${item.app_type}/${item.app_id}`)
    },
    /**
     * quizDetail
    */
    async quizDetail (examId) {
      this.$router.push(`/quiz_detail/${examId}`)
    },
    /**
     * getFormattedDate
     */
    getFormattedDate (datetime, result = "normal") {
      try {
        if (result === "month") {
          return moment(datetime).format("MMM")
        }
        else if (result === "date") {
          return moment(datetime).format("DD")
        }
        else {
          return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
        }
      }
      catch (err) {
        console.error("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    }
  }
}
</script>
